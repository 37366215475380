import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as _ from 'lodash';
import ProductView from '../Catalog/ProductView';
import { fetchBanners, fetchPromotions } from '../../store/actions/global';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import { Banner } from '../../store/responseTypes';
import Spinner from '../../components/Spinner';
import { useTranslation } from 'react-i18next';
import { useStructuredData } from '../../hooks/useStructuredData';

export default () => {
  const { t } = useTranslation();

  const { homeDescriptionStructuredData, homeStructuredData } = useStructuredData();

  const dispatch = useDispatch();

  const banners: Banner[] = useSelector((state: RootState) => state.global.banners);
  const promotions: Banner[] = useSelector((state: RootState) => state.global.promotions);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 6000,
    speed: 2000
  };

  useEffect(() => {
    dispatch(fetchBanners());
    dispatch(fetchPromotions());
  }, [dispatch]);

  return (
    <div>
      <script type="application/ld+json">{JSON.stringify(homeStructuredData)}</script>
      {!banners.length || !promotions.length ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Spinner />
        </div>
      ) : (
        <>
          <Slider {...settings}>
            {banners.map(
              (b) =>
                b.images.length && (
                  <div key={b.id}>
                    <a href={b.images[0].link} target="_blank" rel="noreferrer">
                      <img src={b.images[0].uri} alt={b.images[0].link} />
                    </a>
                  </div>
                )
            )}
          </Slider>
          <div className="categoriesList">
            {promotions.length
              ? _.orderBy(promotions, ['priority'], ['asc']).map((p) => {
                  if (!p.products?.length) return <div />;
                  return (
                    <div key={p.id}>
                      <h3 style={{ fontSize: 21, margin: '30px 0' }}>{p.title}</h3>
                      <div className="row equal">
                        {p.products.map((product: any) => (
                          <div key={product.id} className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                            <ProductView product={product} />
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </>
      )}
      <div style={{ fontSize: 14, lineHeight: 2, marginTop: 50 }}>
        <h1 style={{ marginTop: 30, fontSize: 20, textAlign: 'center' }}>
          {t('main_page_title_text1')}
        </h1>
        <p>{t('main_page_text1')}</p>

        <h2 style={{ marginTop: 30, fontSize: 20, textAlign: 'center' }}>
          {t('main_page_title_text2')}
        </h2>
        <p>{t('main_page_text2')}</p>
        <p>{t('main_page_text3')}</p>
        <p>{t('main_page_text4')}</p>
        <div>
          {t('main_page_text5')}
          <ul style={{ listStyle: 'inside' }}>
            <li>{t('main_page_list_text1')}</li>
            <li>{t('main_page_list_text2')}</li>
            <li>{t('main_page_list_text3')}</li>
          </ul>
        </div>

        <h2 style={{ marginTop: 30, fontSize: 20, textAlign: 'center' }}>
          {t('main_page_title_text3')}
        </h2>
        <div>
          {t('main_page_text6')}
          <ul style={{ listStyle: 'inside' }}>
            <li>{t('main_page_list_text4')}</li>
            <li>{t('main_page_list_text5')}</li>
            <li>{t('main_page_list_text6')}</li>
            <li>{t('main_page_list_text7')}</li>
            <li>{t('main_page_list_text8')}</li>
          </ul>
          {t('main_page_text7')}
        </div>

        <h2 style={{ marginTop: 30, fontSize: 20, textAlign: 'center' }}>{t('main_page_text8')}</h2>
        <p>{t('main_page_text9')}</p>

        <script type="application/ld+json">{JSON.stringify(homeDescriptionStructuredData)}</script>
      </div>
    </div>
  );
};
