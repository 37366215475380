import { productsFields } from '../../store/constansts';
import { ApiClient } from '../ApiClient';
import handleError from '../../utils/integrations/sentry';
import { AxiosError } from 'axios';
import { SearchResponse } from './types';
import * as _ from 'lodash';

export async function getProductsBySearchValue(searchValue: string) {
  try {
    const params = {
      query: searchValue,
      take: 5,
      fields: productsFields,
      embed: 'images',
      inStock: true
    };

    const response = await ApiClient.get<typeof params, SearchResponse>('/products/search', {
      params
    });

    return {
      data: _.sortBy(response.data, ['price']),
      count: response.count
    };
  } catch (error) {
    handleError(error as AxiosError, 'fatal', { searchValue });

    return {
      data: [],
      count: 0
    };
  }
}
